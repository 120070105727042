<template>
    <div>
        <el-page-header @back="backPrivious" content="产品库颜色搜索"></el-page-header>
        <div class="banner">
            <img src="@/assets/banner_colorsearch.png" alt="">
        </div>
        <el-breadcrumb style="padding: 20px 0;" class="f-c-e" separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/ProductLibrary' }">
                <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
                <span> 当前位置： 产品库</span>
            </el-breadcrumb-item>
            <el-breadcrumb-item>产品库颜色搜索</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="centre f-jc">
            <div class="color_panel">
                <div class="color_panelBox f-c-c" :style="{ 'background': picker_color }">
                    <div class="content">
                        <span v-if="csState == 1" class="poundsign">#</span>
                        <span v-if="csState == 1" class="color_text">{{ picker_color ? picker_color.split('#')[1] : ''
                        }}</span>
                        <span v-if="csState == 2" class="color_text">{{ picker_colorText }}</span>
                        <img class="copy" src="@/assets/copy.png" alt="" @click="copy()">
                    </div>
                </div>
                <div v-if="picker_color" class="tip">目前选定颜色预览</div>
                <div v-else class="tip">请点击右方按钮选择颜色进行产品搜索</div>
            </div>
            <div class="btns">
                <div class="btn blue" @click="isColorPaletteDialog = true">调色板取色</div>
                <div class="btn blue" @click="PreviewVisible = true">色库选色</div>
                <div class="searchBox f-c-b">
                    <el-select class="select-box" v-model="productType" placeholder="请选择" @change="changeProductType">
                        <el-option v-for="item in options" :key="item.value" :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                    <div class="btn1 gray"
                        @click="Searchgoods(), $removeStorage('fetchDataSize'), $removeStorage('fetchDataPage')">搜索产品</div>
                </div>
            </div>

        </div>
        <div class="productList">
            <div class="title" v-show="tipColorName && total">产品列表</div>
            <div class="tip" v-show="tipColorName && total">已搜索到与“{{ tipColorName }}”相似颜色的{{ total }}件产品</div>
            <div class="product_list f-w" v-if="productList && productList.length">
                <div class="product_item" v-for="(item, index) in productList" :key="index"
                    @click="toDetailPage(item.productId)">
                    <img :src="item.img1Url" alt="">
                    <div class="f-c-b firstline">
                        <div class="firstLeft">
                            <div class="name">{{ item.productName }}</div>
                            <div class="priceBox">
                                <span class="unit" v-if="item.unitPrice != 0.00">{{ item.valuationCurrency }}</span>
                                <span :class="item.unitPrice != 0.00 ? 'price' : 'unit'">{{ item.unitPrice == 0.00 ? '暂无报价'
                                    : item.unitPrice }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="secondline">
                        <span>色差值：ΔE*ab{{ item.diffV }}(相似度：{{ (100 - (item.diffV * 3)).toFixed(2) }}%)</span>
                    </div>
                    <div class="thirdline f-c-b">
                        <div class="id" v-if="item.productNumber">产品编号:{{ item.productNumber }}</div>
                        <div :class="item.shortageFlag == 'Y' ? 'stateY' : 'stateN'">{{ item.shortageFlag == 'Y' ? '对外显示缺货' :
                            '在售' }}</div>
                    </div>
                    <div :style="{ 'background': item.rgbValue }" class="contrastbtn f-c-c" @click.stop="Contrast(item)">
                        <div class="contrasttext">颜色对比 <i class="el-icon-arrow-right"></i></div>
                    </div>

                    <div class="cornermark">{{ item.productTypeStr }}</div>
                </div>
                <Pagination v-if="productList && productList.length" :total="total" :pageNum.sync="currentPage"
                    :limit.sync="size" :layout="layout" @pagination="fetchData" />
            </div>
            <div v-if="iSempty" class="f-fr-jc empty">
                <span style="margin-top: 10px;">未搜索到相关产品</span>
            </div>
        </div>
        <ColorDialog :PreviewVisible="PreviewVisible" @handleChangeDialog="handleChangeDialog" :key="new Date().getTime()">
        </ColorDialog>
        <ColorPaletteDialog :PreviewVisible="isColorPaletteDialog" @handleChangePaletteDialog="handleChangePaletteDialog"
            :pickerColor="csState == 1 ? picker_color : ($getStorage('ColorPalette_color') ? $getStorage('ColorPalette_color') : '#000000')"
            :key="new Date().getTime() + 1"></ColorPaletteDialog>
        <DialogLogin :loginBoxVisible="loginBoxVisible" @CloseLoginBox="CloseLoginBox"></DialogLogin>
        <!-- 职业 -->
        <DialogStanding :jodBoxVisible="jodBoxVisible" @CloseJobBox="CloseJobBox"></DialogStanding>
        <Dialog :title="'颜色对比'" width="810px" :visible="dialogShow" @closeDialog="closeDialog" class="commonDialog">
            <div class="f-cl-c-c">
                <div class="product">
                    <div class="tit">对应产品</div>
                    <div class="productbox f-c-b" @click="toDetailPage(goodsDetails.productId)">
                        <el-image class="elimg" :src="goodsDetails.img1Url" alt=""></el-image>
                        <div class="productinfo f-cl-b">
                            <div class="f-c-b">
                                <div class="productname">
                                    {{ goodsDetails.productName }}
                                </div>
                                <div class="cornermark">{{ goodsDetails.productTypeStr }}</div>
                            </div>
                            <div class="f-c-b" style="margin-top: 30px;">
                                <div class="productNumber">{{ goodsDetails.productNumber }}</div>
                                <div class="priceBox">
                                    <span class="unit" v-if="goodsDetails.unitPrice != 0.00">{{
                                        goodsDetails.valuationCurrency }}</span>
                                    <span :class="goodsDetails.unitPrice != 0.00 ? 'price' : 'unit'">{{
                                        goodsDetails.unitPrice == 0.00 ?
                                        '暂无报价'
                                        : goodsDetails.unitPrice }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="contrastbox flex">
                    <div class="f-c-c" style="width: 50%;border-radius: 30px 0 0 30px;"
                        :style="{ 'background': goodsDetails.rgbValue }">
                        <div class="contrastitem">
                            产品颜色
                        </div>
                    </div>
                    <div class="f-c-c" style="width: 50%;border-radius: 0 30px 30px 0;"
                        :style="{ 'background': picker_color }">
                        <div class="contrastitem">
                            选择颜色
                        </div>

                    </div>

                </div>
                <div class="contrasttext flex">
                    <div style="width: 50%;height: 40px;">
                        <div class="textitem">
                            {{ goodsDetails.rgbValue }}
                        </div>
                    </div>
                    <div class="f-c-c" style="width: 50%;height: 40px;">
                        <div class="textitem">
                            {{ tipColorName }}
                        </div>
                    </div>
                </div>
                <div v-if="picker_colorName" class="contrasttext flex">
                    <div style="width: 50%;">
                        <div class="textitem3">
                        </div>
                    </div>
                    <div class="f-c-c" style="width: 50%;">
                        <div class="textitem3">
                            {{ picker_colorName }}
                        </div>
                    </div>
                </div>
                <div class="contrasttext flex">
                    <div style="width: 50%;height: 40px;">
                        <div class="textitem2">
                            色差值：ΔE*ab{{ goodsDetails.diffV }}
                        </div>
                    </div>
                    <div class="f-c-c" style="width: 50%;height: 40px;">
                        <div class="textitem2">
                            (相似度：{{ (100 - (goodsDetails.diffV * 3)).toFixed(2) }}%)
                        </div>
                    </div>
                </div>
                <div class="tip">
                    色彩预览会因终端设备的显示效果产生差异 仅供参考使用。
                </div>
            </div>

        </Dialog>
    </div>
</template>
<script>
import CryptoJS from '@/utils/DES.js';
import RGB2Lab from '@/utils/RGB2Lab';
import { PaginationRetention1 } from '@/mixins/PaginationRetentionMixin';
import ColorDialog from '@/components/ColorDialog.vue';
import ColorPaletteDialog from '@/components/ColorPaletteDialog.vue';
import { UnitAccount } from "@/utils/attributeValue1Options"
export default {
    name: 'ProductLibrary_Coloursearch',
    inject: ['reload'],
    mixins: [PaginationRetention1],
    components: {
        ColorDialog, ColorPaletteDialog
    },
    data() {
        return {
            PreviewVisible: false,
            isColorPaletteDialog: false,
            picker_color: '',  // 调色板颜色
            picker_colorText: '',
            picker_colorName: '',
            tipColorName: '',
            csState: '',
            colorId: '',
            productList: [], // 商品列表
            iSempty: false,
            options: [],
            productType: this.$getStorage('ProductLibrary_productType') ?? 0,
            loginBoxVisible: false,  // 登录弹窗
            jodBoxVisible: false,  // 职业弹窗
            enterpriseId: '',
            dialogShow: false,
            goodsDetails: {}
        }
    },
    created() {
        let that = this,
            { enterpriseId } = JSON.parse(this.$UrlEncode.decode(this.$route.query.key));
        this.enterpriseId = enterpriseId ?? 0
        that.findSearchProductTypeList();
        that.productType = this.$getStorage('ProductLibrary_productType') ?? 0
        let params = this.$getStorage('ProductLibrary_params') ?? '';
        that.picker_color = that.$getStorage('picker_color') ?? '';
        that.picker_colorName = that.$getStorage('picker_colorName') ?? '';
        that.csState = that.$getStorage('csState') ?? '';
        if (that.csState == 1) {
            // that.productType = params.productType
            that.size = params.size
            that.page = params.page
            that.picker_colorText = that.$getStorage('picker_color') ?? ''
            that.Searchgoods();
        } else if (that.csState == 2) {
            that.colorId = params.colorId;
            that.size = params.size;
            that.page = params.page;
            that.picker_colorText = that.$getStorage('picker_colorText') ?? '';
            that.Searchgoods();
        }
        let InvestigateFlag = this.$getStorage('InvestigateFlag');
        this.jodBoxVisible = InvestigateFlag == 'Y' || InvestigateFlag == 'I' ? true : false;
    },
    methods: {
        // 获取产品类型
        async findSearchProductTypeList() {
            let data = {
                page: 1,
                size: 10
            }
            await this.$http.findSearchProductTypeList(data).then(res => {
                if (res.code == 200) {
                    let records = res.data.records,
                        odj = {};
                    records.forEach(item => {
                        odj = {
                            value: item.productTypeId,
                            label: item.productTypeName
                        }
                        this.options.push(odj)
                    });
                }
                this.$setStorage('ProductTypeList',this.options);
            })
        },
        // 
        getDate() {
            if (this.csState) {
                this.Searchgoods(this.paging, this.pagingSize);
            }
        },
        // 复制
        copy() {
            let that = this;
            if (that.csState == 1) {
                that.$copyText(this.picker_colorText.split('#')[1]).then(
                    function (e) {
                        console.log(e)
                        that.$common.message('复制成功', 'success')
                    },
                    function (e) {
                        console.log(e)
                        that.$common.message('复制失败', 'error')
                    }
                );
            } else {
                that.$copyText(this.picker_colorText).then(
                    function (e) {
                        console.log(e)
                        that.$common.message('复制成功', 'success')
                    },
                    function (e) {
                        console.log(e)
                        that.$common.message('复制失败', 'error')
                    }
                );
            }

        },
        // 搜索商品按钮
        async Searchgoods(page = this.page, size = this.size,) {
            let that = this,
                { R, G, B } = RGB2Lab.hexToRgba(that.picker_color),
                params = {};
            const RGBtoLAB = RGB2Lab.RGBToXYZ(R, G, B);
            if (that.csState == 1) { //1: lab值搜索
                params = {
                    labA: RGBtoLAB.labA * 17 + 456,
                    labB: RGBtoLAB.labB * 19 + 789,
                    labL: RGBtoLAB.labL * 13 + 123,
                    csState: that.csState, //搜索状态(1: lab值搜索2: 色彩id搜索)
                    productType: that.productType, //产品类型(不传为类型1,传0查询全部)
                    lastModifyUserId: that.$getStorage('userId'),
                    size,
                    page,
                }
                this.$setStorage('ProductLibrary_params', params);
                this.$setStorage('picker_color', that.picker_color);
                this.$setStorage('csState', that.csState);
            } else if (that.csState == 2) { //2: 色彩id搜索
                params = {
                    csState: that.csState, //搜索状态(1: lab值搜索2: 色彩id搜索)
                    colorId: that.colorId, //色彩ID
                    productType: that.productType, //产品类型(不传为类型1,传0查询全部)
                    lastModifyUserId: that.$getStorage('userId'),
                    size,
                    page,
                }
                this.$setStorage('ProductLibrary_params', params);
                this.$setStorage('picker_color', that.picker_color);
                this.$setStorage('picker_colorText', that.picker_colorText);
                this.$setStorage('picker_colorName', that.picker_colorName);
                this.$setStorage('csState', that.csState);
            }
            if (that.csState) {
                await that.$http.findColorSearchEnterpriseProductList(params).then(res => {
                    if (res.code == 200) {
                        let records = res.data.records;
                        let isNum = '';
                        records.forEach(item => {
                            item.productTypeStr = UnitAccount.getproductType(item.productType)
                            item.rgbValue = '#' + CryptoJS.decrypt(item.rgbValue)
                        })
                        if (res.data.size - res.data.records.length != 0) {
                            isNum = res.data.records.length - (res.data.size * (res.data.current - 1) + res.data.records.length - 60)
                        }
                        that.productList = isNum ? records.slice(0, isNum) : records;
                        that.productList.forEach(item => {

                            let unitPrice = parseFloat(item.unitPrice);
                            item.unitPrice = unitPrice.toFixed(2);
                        })
                        that.currentPage = res.data.current;
                        that.total = (res.data.total > 60) ? 60 : res.data.total;
                        that.iSempty = res.data.records.length == 0 ? true : false;
                        that.tipColorName = that.picker_colorText;
                    }
                })
            } else {
                that.$common.message('请先选择颜色！', 'warning')
            }
        },
        // 跳转到商品详情
        toDetailPage(productId) {
            if (!this.$getStorage('token')) {
                this.loginBoxVisible = true
            } else {
                this.$router.push({
                    name: 'ProductLibraryDetail',
                    query: {
                        key: this.$UrlEncode.encode(JSON.stringify({
                            id: productId
                        }))
                    }
                })
            }
        },
        // 
        handleChangeDialog(value, picker_color, picker_colorText, colorId, colorName) {
            this.PreviewVisible = value
            this.picker_color = picker_color ?? this.picker_color;
            this.picker_colorText = picker_colorText ?? this.picker_colorText;
            this.picker_colorName = colorName ?? this.picker_colorName;
            this.colorId = colorId ?? this.colorId;
            this.csState = picker_color ? 2 : this.csState;
        },
        handleChangePaletteDialog(value, picker_color) {
            this.isColorPaletteDialog = value;
            this.picker_color = picker_color ?? this.picker_color;
            this.picker_colorText = picker_color ?? this.picker_colorText;
            this.csState = picker_color ? 1 : this.csState;
            this.picker_colorName = '';
        },
        CloseLoginBox() {
            this.loginBoxVisible = false
            if (this.$getStorage('token')) {
                let InvestigateFlag = this.$getStorage('InvestigateFlag');
                this.jodBoxVisible = InvestigateFlag == 'Y' || InvestigateFlag == 'I' ? true : false;
            }
        },
        // 关闭职业弹窗
        CloseJobBox() {
            this.jodBoxVisible = false;
            this.reload();
        },
        changeProductType(value) {
            this.$setStorage('ProductLibrary_productType', value);
        },
        // 
        Contrast(data) {
            this.dialogShow = true;
            this.goodsDetails = data;
            let unitPrice = parseFloat(this.goodsDetails.unitPrice);
            this.goodsDetails.unitPrice = (unitPrice.toFixed(2));
        },
        // 关闭弹框
        closeDialog(data) {
            this.dialogShow = data;
        },
    },
    beforeDestroy() {
        this.$removeStorage('ColorSearchValue')
        this.$removeStorage('ColorStateValue')
        this.$removeStorage('labValue')
    }
}

</script>

<style lang="less" scoped>
.banner {
    height: 100px;
    width: 1280px;
    margin-bottom: 40px;

    img {
        width: 100%;
        height: 100%;
    }
}

.el-breadcrumb ::v-deep .el-breadcrumb__inner {
    color: #666666 !important;
    font-weight: 400 !important;
    font-size: 14px;
}

.el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
    color: #0363FA !important;
    font-weight: 400 !important;
    font-size: 14px;
}

.centre {
    margin-top: 60px;

    .color_panel {
        .color_panelBox {
            width: 450px;
            height: 220px;
            border: 1px solid #999999;
            border-radius: 20px;
            .content {
                width: 240px;
                height: 60px;
                background: #FFFFFF;
                border: 1px solid #999999;
                border-radius: 20px;
                padding: 0 20px;
                position: relative;

                .poundsign {
                    line-height: 60px;
                    font-size: 24px;
                    color: #333333;
                }

                .color_text {
                    margin-left: 25px;
                    line-height: 60px;
                    font-size: 24px;
                    color: #333333;
                }

                .copy {
                    position: absolute;
                    right: 30px;
                    top: 20px;
                    margin-left: 130px;
                    height: 20px;
                    width: 20px;
                    cursor: pointer;
                }
            }
        }

        .tip {
            font-size: 18px;
            font-weight: 400;
            color: #333333;
            line-height: 60px;
            text-align: center;
        }

    }

    .btns {
        margin-left: 60px;
        height: 220px;

        .btn {
            width: 230px;
            height: 40px;
            border-radius: 10px;
            line-height: 40px;
            text-align: center;
            cursor: pointer;
        }

        .blue {
            color: #FFFFFF;
            background: linear-gradient(79deg, #0873F4 0%, #39A8F9 100%);
            margin-bottom: 50px;
        }

        .searchBox {
            width: 230px;

            .select-box {
                width: 110px !important;

            }

            /deep/.el-input__inner {
                width: 110px !important;
                height: 40px !important;
                line-height: 40px !important;
            }

            .btn1 {
                margin-left: 10px;
                width: 110px !important;
                height: 40px;
                border-radius: 10px;
                line-height: 40px;
                text-align: center;
                cursor: pointer;
                color: #2971FA;
                background: #EFF4FA;
            }
        }

    }

}

.productList {
    margin-top: 40px;
    .title {
        font-size: 24px;
        font-weight: 500;
        color: #000000;
    }

    .tip {
        margin-top: 15px;
        font-size: 16px;
        font-weight: 500;
        color: #999999;
    }

    .product_list {
        margin-top: 30px;

        .product_item {
            width: calc(20% - 30px);
            margin-right: 10px;
            margin-bottom: 10px;
            padding-bottom: 10px;
            background: #F6F8FB;
            border-radius: 10px;
            cursor: pointer;
            position: relative;

            img {
                width: 100%;
                height: 170px;
                border-radius: 10px 10px 0 0;
            }

            .firstline {
                margin: 10px;
                .firstLeft {
                    width: 200px;

                    .name {
                        margin-bottom: 5px;
                        font-size: 18px;
                        font-weight: 500;
                        color: #333333;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        line-height: 25px;

                    }

                    .priceBox {
                        font-weight: 500;
                        color: #FF4E36;

                        .unit {
                            font-size: 14px;
                        }

                        .price {
                            font-size: 18px;
                            font-weight: 500;
                        }

                    }
                }

                .firstRight {
                    height: 40px;
                    width: 110px;
                    border-radius: 10px;

                    .Color {
                        border-radius: 9px;
                        background: #FFF;
                        font-size: 12px;
                        padding: 5px;
                    }
                }

            }

            .secondline {
                margin: 10px;
                font-size: 12px;
                font-family: HarmonyOS Sans SC;
                font-weight: 400;
                color: #333333;
            }

            .thirdline {
                margin: 10px;

                .id {
                    width: 80%;
                    font-size: 12px;
                    font-weight: 500;
                    color: #333333;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .stateY {
                    font-size: 16px;
                    font-family: HarmonyOS Sans SC;
                    font-weight: 500;
                    color: #FF4E36;
                }

                .stateN {
                    font-size: 16px;
                    font-family: HarmonyOS Sans SC;
                    font-weight: 500;
                    color: #06BC95;
                }
            }

            .contrastbtn {
                height: 40px;
                width: 110px;
                border-radius: 10px;
                margin: 0 auto;
                .contrasttext {
                    width: 68px;
                    height: 18px;
                    border-radius: 9px;
                    background: #FFF;
                    font-size: 12px;
                    padding: 2px;
                    text-align: center;
                    line-height: 18px;
                }
            }
            .Id {
                margin-top: 10px;
                font-size: 14px;
                font-weight: 500;
                color: #333333;
            }

            .cornermark {
                position: absolute;
                right: 5px;
                top: 5px;
                border-radius: 5px;
                background: #ffffff;
                text-align: center;
                height: 14px;
                line-height: 14px;
                font-size: 12px;
                color: #699EF2;
                padding: 3px;
            }

            &:hover {
                transform: translateY(-3px);
            }
        }

    }
}

.commonDialog {
    /deep/ .el-dialog {
        border-radius: 20px;

        /deep/ .el-dialog__title {
            font-size: 36px !important;
            font-family: HarmonyOS Sans SC !important;
            font-weight: 500 !important;
            color: #000000 !important;
        }
    }

    /deep/.el-dialog__header {
        padding: 30px;
    }

    /deep/ .el-dialog__title {
        margin-top: 40px !important;
        font-size: 36px !important;
        font-family: HarmonyOS Sans SC !important;
        font-weight: 500 !important;
        color: #000000 !important;
    }

    .product {
        width: 400px;

        .tit {
            font-size: 18px;
            font-family: HarmonyOS Sans SC;
            font-weight: 400;
            color: #333333;
            margin-bottom: 10px;
        }

        .productbox {
            height: 75px;
            border-radius: 30px;
            border: 1px solid #E2E2E2;
            padding: 24px 30px;
            cursor: pointer;

            .elimg {
                height: 75px;
                width: 75px;
                border-radius: 20px;
            }

            .productinfo {
                width: 70%;

                .productname {
                    width: 75%;
                    font-size: 23px;
                    line-height: 30px;
                    font-family: HarmonyOS Sans SC;
                    font-weight: 400;
                    color: #000000;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                .cornermark {
                    border-radius: 5px;
                    background: #E6F1FE;
                    text-align: center;
                    height: 14px;
                    line-height: 14px;
                    font-size: 12px;
                    color: #699EF2;
                    padding: 3px;
                }

                .productNumber {
                    font-size: 17px;
                    font-family: HarmonyOS Sans SC;
                    font-weight: 400;
                    color: #999999;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }


                .priceBox {
                    font-weight: 500;
                    color: #FF4E36;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    .unit {
                        font-size: 14px;
                    }

                    .price {
                        font-size: 18px;
                        font-weight: 500;
                    }

                }


            }
        }
    }

    .contrastbox {
        width: 400px;
        height: 150px;
        border-radius: 30px;
        margin-top: 34px;

        .contrastitem {
            background: #FFF;
            width: 115px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            font-size: 20px;
            font-family: HarmonyOS Sans SC;
            font-weight: 500;
            color: #333333;
            border-radius: 18px;
        }
    }

    .contrasttext {
        width: 400px;
        margin-top: 20px;

        .textitem {
            margin: 0 auto;
            width: 150px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border-radius: 15px;
            background: #EFF2F7;
            color: #333;
        }

        .textitem2 {
            margin: 0 auto;
            width: 150px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border-radius: 15px;
            color: #666666;
        }

        .textitem3 {
            margin: 0 auto;
            width: 150px;
            text-align: center;
            color: #666666;
        }
    }

    .tip {
        width: 340px;
        font-size: 18px;
        font-family: HarmonyOS Sans SC;
        font-weight: 400;
        color: #999999;
        text-align: center;
        line-height: 30px;
    }

}
</style>